import React from "react"
import Layout from "../components/Layout"
import Navigation from "../components/Navigation"
import { navigate } from "gatsby"
import * as styles from "../assets/css/indexNavigation.module.css"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

const Card4 = ({ data }) => {
  const { image, back } = data
  return (
    <Layout>
      <div className={styles.backResponsive}>
        <div
          className={styles.back}
          onClick={() => navigate(-1)}
          onKeyDown={() => navigate(-1)}
          role="button"
          tabIndex="0"
        >
          <GatsbyImage image={getImage(back)} title={back.title} alt="Zurück" />
        </div>
      </div>

      <div className={styles.neurologeHeader}>Ganzheitlich Denken</div>
      <div className={styles.neurologeContentContainer}>
        <div className={styles.neurologeText1}>
          <p>
            Für mich bedeutet Hilfestellung zur Heilung, einen ganzheitlichen
            Ansatz zu nehmen, der nichts ausschließt.
          </p>

          <div className={styles.zitatText}>
            „Das ganze ist mehr als die Summe seiner Teile.“{" "}
            <h4>Aristoteles</h4>
          </div>
        </div>
      </div>
      <div className={styles.neurologeContentImageContainerGanzheitlich}>
        <div className={styles.neurologeContentImageGanzheitlich}>
          <GatsbyImage
            image={getImage(image)}
            title={image.title}
            alt="Computertomographie"
          />
        </div>
      </div>

      <div className={styles.neurologeContentContainer2Ganzheitlich}>
        <div className={styles.neurologeText2Ganzheitlich}>
          <p>
            Ein Mensch ist mehr als die Summe funktionierender Nervenzellen,
            intakter Haut, zahlenmässig ausreichender Blutkörperchen, normaler
            Blutfette etc..
          </p>
          <p>
            Mir gefällt ein therapeutischer Ansatz, in dem sowohl
            wissenschaftliche Erkenntnisse der „modernen“ „Schulmedizin“ in
            vernünftiger Art und Weise zur Anwendung kommen, als auch Methoden
            der „Komplementärmedizin“, welche in stärkerem Masse die
            menschlichen Dimensionen von Geist und Seele sowie energetischer
            Balance berücksichtigen und den Menschen wirklich als „Ganzes“
            sehen.
          </p>
          <p>
            Neben dem klassischen Angebot meiner neurologischen Ordination (wie
            Nervenmessungen, Parkinson-Therapie etc.) habe ich an
            komplementärmedizinischen Methoden die Ausbildung zur
            Neuraltherapie/Regulationstherapie absolviert, sowie zur
            orthomolekularen Therapie/Nährstoffmedizin, und biete diese
            Therapieformen in meiner Ordination an.
          </p>
          <p>
            Weiters bestehet ein Netzwerk mit KollegInnen und TherapeutInnen,
            die weitere komplementärmedizinsche Ansätze wie Akupunktur,
            Osteopathie, cranio-sacral-Therapie u.a. anbieten.
          </p>
          <p>
            Was die aktuelle wissenschaftlich fundierte „Schulmedizin“ betrifft,
            bilde ich mich ständig bei diversen Veranstaltungen und Seminaren
            aus dem Gebiet Neurologie und Allgemeinmedizin weiter und besitze
            das aktuelle Fortbildungsdiplom der österr. Ärztekammer.
          </p>
          <p>
            So ist es mir möglich, meinen Patienten ein breitgefächertes Angebot
            an Behandlungsoptionen anzubieten.
          </p>
        </div>
      </div>

      <Navigation></Navigation>
    </Layout>
  )
}

export default Card4

export const pageQuery = graphql`
  query GanzheitlichQuery {
    image: contentfulAsset(contentful_id: { eq: "3EzLtw7K06DEUO6umGE7QH" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
    back: contentfulAsset(contentful_id: { eq: "6fycJDkI3crd9QifanGGwI" }) {
      gatsbyImageData(quality: 100)
      title
      description
    }
  }
`
